@import "../variables";
@import "../helpers/mixins";

.museum {
  // &.section-rv2 {
  padding: 0px 10px;
  // }
  &__slide {
    padding-bottom: 140px;
  }

  &.hero-museum {
    padding-bottom: 0;
    .card2 {
      padding: 0;
      background-color: transparent;

      .card2__box {
        padding: 0 5px;
      }

      @include m {
        &.portrait {
          height: 120px;
        }

        &.landscape {
          height: 120px;
          max-width: 200px !important;
        }

        .card2__preview {
          height: 120px;
        }
      }
    }

    .recently__center .swiper-slide {
      margin: 0;
      @include m {
        width: 35% !important;
      }
    }

    .welcome_text {
      padding-bottom: 60px;

      @include m {
        padding-bottom: 20px;
      }
    }
  }

  .seed_list .card2__box {
    padding: 16px;
  }

  &__herosection {
    &_header {
      @include dm-sans;
      font-weight: 700;
      font-size: 64px;
      line-height: 64px;
      text-align: center;
      letter-spacing: -0.02em;
      color: $neutrals2;
      text-align: center;
      margin-bottom: 50px;
      padding-top: 50px;

      @include dark {
        color: $neutrals7;
      }

      @include m {
        @include dm-sans;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        margin: 16px 24px;
      }
    }

    &_title {
      @include body-bold-1;
      color: $neutrals4;
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      @include t {
        margin-bottom: 100px;
      }

      @include m {
        @include caption-1;
        margin-bottom: 16px;
      }
    }

    @include m {
      margin-top: 49px;
    }

    &_more {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
      height: 1px;
    }

    &_btn {
      background: $primary1;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      border: 12px solid $primary2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 2;
      top: -40px;

      @include m {
        top: -5px;
        width: 115px;
        height: 115px;
        border: 6px solid $primary2;
      }

      @include dark {
        background: $primary2;
        border: 12px solid $primary1;

        @include m {
          border: 6px solid $primary1;
        }
      }

      &:hover {
        background: $primary3;
        // border: 12px solid $primary2;

        // @include m {
        //   border: 6px solid $primary2;
        // }
      }

      // .content {
      //   display: block;

      //   @include t {
      //     display: block;
      //   }

      //   @include m {
      //     display: none;
      //   }
      // }

      .content {
        @include m {
          width: 120px;
          height: 120px;
        }
      }

      .go_to_page {
        position: absolute;
        width: 67px;
        height: 67px;
        border-radius: 100px;
        background-color: $primary2;
        display: flex;
        align-items: center;
        justify-content: center;

        @include m {
          width: 36px;
          height: 36px;
        }

        @include dark {
          background-color: $primary1;
          .icon {
            fill: $primary2;
          }
        }

        .icon {
          width: 26px;
          height: 26px;
          fill: $primary1;
          @include m {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    &_preview {
      @include m {
        height: 120px;
      }
    }

    &_museumnfts {
      margin-bottom: 40px;

      @include m {
        margin-bottom: 16px;
      }
    }
  }

  &__staking {
    background-color: $primary2;
    padding-top: 80px;
    padding-bottom: 80px;

    @include dark {
      background-color: $primary1;
    }

    &_title {
      @include dm-sans;
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 64px;
      text-align: center;
      margin-bottom: 24px;
    }

    &_subject {
      @include main-font;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      align-items: center;
      text-align: center;
      /*color: $primary1;*/
      margin-bottom: 32px;
    }

    &_info {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */
      // display: flex;
      // align-items: center;
      text-align: center;
      /* Neutrals/4 */
      color: #777e91;
      margin-bottom: 48px;

      .link {
        color: $primary3;
      }
    }

    &_photo {
      width: 100%;
      object-fit: cover;
      height: 594px;

      @include m {
        object-fit: contain;
        height: 170px;
      }
    }
  }

  &__Introduction {
    background-color: $white;
    padding-top: 80px;
    padding-bottom: 80px;

    @include dark {
      background-color: $primary1;
    }

    &_box1 {
      display: flex;
      gap: 32px;

      @include m {
        display: block;
      }

      @include t {
        display: block;
      }
    }

    &_icon_container {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: rgba($neutrals2, 0.12);
      display: flex;
      justify-content: center;
      align-items: center;

      @include dark {
        background-color: rgba($neutrals7, 0.12);
      }
    }

    &_box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px;
      gap: 24px;
      background: $white;
      border: 1px solid $neutrals6;

      @include m {
        margin-bottom: 20px;
      }

      @include t {
        margin-bottom: 20px;
      }

      @include dark {
        background: $primary1;
        border: 1px solid $neutrals3;

        &:hover {
          box-shadow: 0px 24px 64px -48px rgba(31, 47, 70, 0.08);
          border: none;
          background: $neutrals1;
          padding: 33px;
        }
      }

      &:hover {
        box-shadow: 0px 24px 64px -48px rgba(31, 47, 70, 0.08);
        border: none;
        padding: 33px;
      }
    }

    &_title {
      @include main-font;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: $neutrals3;

      @include dark {
        color: #ffffff;
      }
    }

    &_discription {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $neutrals3;
      margin-top: auto;

      @include dark {
        color: $neutrals4;
      }
    }
  }

  &__whatis {
    padding-top: 50px;
    padding-bottom: 200px;

    &_step1 {
      display: flex;
      height: 112px;
      margin-bottom: 20px;
    }

    &_museumdetail {
      width: 40%;
      margin-bottom: 10px;
    }

    &_museumphoto1 {
      margin-top: -40px;
    }

    &_museumphoto2 {
      margin-top: -80px;
    }

    &_museumphoto3 {
      margin-top: -100px;
    }

    &_item {
      border-top: 1px solid $neutrals6;

      @include dark {
        border-color: $neutrals3;
      }

      &.active {
        background: $primary2;
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-bottom: 30px;
        padding: 0 24px;

        @include dark {
          background: $primary1;
        }

        .musume__whatis_head {
          &:before {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    &_head {
      position: relative;
      padding: 32px 40px 32px 0;
      @include body-bold-2;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: color 0.2s;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 10px;
        height: 6px;
        transform: translateY(-50%);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E")
          no-repeat 50% 50% / 100% auto;
        transition: transform 0.2s;
      }
    }

    &_body {
      display: none;
      padding-bottom: 32px;
      @include body-2;
      color: $neutrals4;
    }

    &_tab {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
    }

    &_tab button {
      background-color: inherit;
      outline: none;
      cursor: pointer;
      padding: 10px 15px;
      transition: background-color 0.3s;
      font-size: 16px;
      text-align: left;
    }
    &_tabcontent {
      padding: 10px;
      background-color: #fff;
      display: none;
      text-align: justify;
      line-height: 1.5;
      font-size: 14px;
      border-left: 2px solid $primary3;
    }

    &_circle {
      margin-left: -26px;
      position: absolute;
      padding: 0.25rem;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: -15px;

      .musume__whatis_circle::after {
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        background-color: #8b4513;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: rotate(90deg) translateX(-50%);
      }
    }

    &_dot {
      margin: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $neutrals4;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;
    }

    &_tab button.active .musume__whatis_circle {
      border: solid 1px $primary3;
    }

    &_tab button.active .musume__whatis_dot {
      background-color: $primary3;
      border: solid 1px $primary3;
    }

    &_tablinks {
      display: flex;
      @include main-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-left: 2px solid $primary3;

      &.active {
        color: $primary3;
        @include main-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  //what is museum steps

  &__header {
    color: $neutrals2;
    @include dm-sans;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    margin: auto;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 80px 0 48px 0;

    @include dark {
      color: $neutrals7;
    }
  }

  &__guide {
    display: flex;
    align-items: center;
    margin-bottom: 80px;

    &_content {
      flex: 0 0 45%;
    }

    &_photos {
      flex: 1 0 50%;
      max-width: 100%;
      height: 358px;
      // background: $primary2;
      box-shadow: 0px 56px 56px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-top: 0px;
      margin-bottom: 0px;

      img {
        object-fit: contain;
        border-radius: 12px;

        @include m {
          width: 100%;
          height: 210px;
        }
      }

      @include t {
        margin: auto;
        margin-bottom: 40px;
      }

      @include m {
        margin-bottom: 40px;
        height: unset;
      }

      @include s {
        // margin-top: -85px;
        margin-bottom: 40px;
        height: unset;
      }
    }

    @include t {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    .text-muted {
      color: $primary1 !important;

      @include dark {
        color: $primary2 !important;
      }
    }
  }

  &__Description {
    margin-top: -1.5rem;
    margin-left: 20px;
    // text-align: justify;
    @include body-2;
  }

  .d-none {
    display: none;
  }

  &__step {
    padding: 12px;
  }

  &__font_normal {
    font-weight: normal;
  }

  ul.museum-stepper {
    counter-reset: section;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;

    .museum-stepper-circle {
      position: relative;
      display: flex !important;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }

  &__outline {
    display: inline-block;
    border: 6px solid $primary3 !important;
    border-radius: 50%;
    margin: 0;
    padding: 0;
  }

  .museum-stepper-circle:after {
    border: 3px solid $primary3;
  }

  .form-stepper-vertical {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  ul.museum-stepper > li {
    margin-bottom: 0.625rem;
    -webkit-transition: margin-bottom 0.4s;
    -o-transition: margin-bottom 0.4s;
    transition: margin-bottom 0.4s;
    flex-direction: column;
    text-align: start;
  }

  .form-stepper-vertical {
    > li {
      margin-bottom: 0 !important;
    }

    li {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-transition: 0.5s;
      transition: 0.5s;

      &:not(:last-child) {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 10px;
          left: 5px;
          width: 1px;
          height: 100%;
          border-left: 2px dashed $neutrals4;
          z-index: 1;
        }
      }

      &:after {
        background-color: #dee2e6;
      }

      &.museum-stepper-completed:after {
        background-color: $primary3;
        border-left: 2px solid $primary3;
      }

      &.museum-stepper-active:after {
        background-color: $primary3;
        border-left: 2px solid $primary3;
      }

      &:last-child {
        flex: unset;
      }
    }
  }

  ul.museum-stepper li .museum-stepper-circle {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 0;
    line-height: 1.7rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.38);
    border-radius: 50%;
    z-index: 4;
    margin-bottom: 46px;
  }

  .museum-stepper {
    .museum-stepper-active {
      .museum-stepper-circle {
        background-color: $primary3 !important;
        background-clip: content-box !important;
        padding: 5px;
        border: 1px solid #8e793e;
        margin-left: -5px;
        @include body-bold-1;

        li {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 30px;
            width: 1px;
            height: 100%;
            border-left: 2px solid $primary3;
          }
        }
      }

      .label {
        color: $primary3 !important;
        display: contents;
        @include body-bold-1;
        @include poppins;

        @include m {
          font-size: 18px;
        }
      }
    }

    .museum-stepper-unfinished .museum-stepper-circle {
      background-color: $neutrals4;
      width: 10px;
      height: 10px;
    }

    .museum-stepper-completed {
      .museum-stepper-circle {
        background-color: $primary3 !important;
        border-left: 2px solid $primary3;
        width: 10px;
        height: 10px;
      }

      .label {
        color: $primary1 !important;

        @include dark {
          color: $primary2 !important;
        }
      }
    }

    .museum-stepper-active span.text-muted,
    .museum-stepper-completed span.text-muted {
      display: none;
    }

    .label {
      @include body-bold-2;
      @include poppins;
    }

    a {
      cursor: pointer;
    }
  }
  //-------
  &__financial {
    position: relative;

    img {
      // height: 485px;
      height: 640px;
      width: 66%;
      object-fit: cover;

      @include t {
        width: 100%;
        height: auto;
      }
    }

    &_title {
      background: $primary2;
      position: absolute;
      // left: 685px;
      // top: 227px;
      left: 57%;
      top: 46%;
      max-width: 664px;
      background-clip: content-box;
      padding: 12px;
      border: 4px solid $primary2;
      box-shadow: 0px 64px 64px -48px #1f2f461f;

      @include dark {
        box-shadow: 0px 64px 64px -48px #1f2f461f;
      }

      @include t {
        position: relative;
        left: 0;
        top: 0;
        max-width: 100%;
        background-clip: inherit;
      }

      @include m {
        position: relative;
        left: 0;
        top: 0;
        max-width: 100%;
        background-clip: inherit;
      }

      @include dark {
        background: $primary1;
        background-clip: content-box !important;
        padding: 12px;
        border: 4px solid $primary1;
      }
    }

    &_header {
      @include hairline-3;
      color: $neutrals2;
      padding: 48px 113px 0 48px;

      span {
        color: $primary3;
      }

      @include t {
      }

      @include m {
        @include body-bold-1;
        padding: 29px 24px 0 24px;
      }

      @include dark {
        color: $primary2;
      }
    }

    &_description {
      @include body-2;
      color: $neutrals4;
      padding: 16px 48px 80px;

      @include m {
        padding: 16px 24px 40px;
      }
    }
  }

  &__calendar {
    display: flex;
    //gap: 16px;
    width: 30%;

    @include t {
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 20px;
    }

    @include m {
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 20px;
    }

    &_title {
      @include hairline-3;
      color: $neutrals2;
      text-align: center;
      margin-bottom: 80px;

      @include dark {
        color: $neutrals7;
      }

      @include m {
        @include body-1;
        font-weight: 700;
        margin-bottom: 40px;
      }
    }

    &_btn {
      width: 25%;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;

      @include t {
        display: none;
      }

      @include m {
        display: none;
      }
    }

    &_box {
      display: inline-flex;
      width: 70%;

      @include t {
        display: none;
      }

      @include m {
        display: none;
      }
    }

    &_upcoming {
      border-bottom: 2px solid $neutrals6;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 24px;
      width: 100%;

      @include t {
        display: block;
      }

      @include m {
        display: block;
      }

      @include dark {
        border-bottom: 2px solid $neutrals3;
      }
    }

    &_weeks {
      @include body-1;
      color: $neutrals4;

      @include m {
        @include caption-1;
      }
    }

    &_number {
      @include hairline-3;
      color: $primary1;
      display: flex;
      gap: 8px;

      @include dark {
        color: $primary2;
      }

      @include m {
        @include body-bold-1;
      }
    }

    &_nav {
      border: 2px solid $neutrals6;
      border-radius: 50%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;

      &.left {
        margin-right: auto;
      }

      &.right {
        margin-left: auto;
      }

      &:active {
        background: $primary1;
        color: $primary2;
      }

      &:hover {
        background: $primary1;
        color: $primary2;

        @include dark {
          background: $primary2;
          color: $primary1;
        }
      }

      &:disabled {
        border: 2px solid $neutrals7;

        i {
          color: $neutrals5;
        }

        @include dark {
          border: 2px solid $neutrals4;

          i {
            color: $neutrals3;
          }
        }
      }

      @include dark {
        border: 2px solid $neutrals3;
        color: $primary2;

        &:active {
          background: $primary2;
          color: $primary1;
        }
      }

      .left {
        transform: rotate(-90deg);
        font-size: 20px;
      }

      .right {
        transform: rotate(90deg);
        font-size: 20px;
      }
    }

    &_nfts {
      display: flex;
      margin-top: 48px;
      gap: 27px;

      @include m {
        margin-top: 8px;
        width: 100%;
      }

      .coming {
        filter: blur(14px);
        -webkit-filter: blur(14px);
      }
    }

    &_empty {
      @include hairline-3;
      text-align: center;
      padding: 50px;
    }
  }

  &__UpComingSection {
    background: $neutrals8;
    @include dark {
      background: $primary1;
    }
  }
  &__UpComingReleased {
    background: $neutrals8;
    @include dark {
      background: $primary1;
    }
    &_cardsection {
      display: flex;
      margin-top: 40px;
      gap: 15px 19px;
    }

    &_week {
      background: $primary3;
      padding: 8px 8px 6px 8px;
      @include button-1;
      border-radius: 4px;
      color: $primary2;
      @include dm-sans;
    }

    &_links {
      display: flex;
      justify-content: center;
    }

    &_link {
      flex-shrink: 0;
      padding: 0px 16px 4px 0px;
      /*padding: 6px 12px;*/
      /*border-radius: 14px;*/
      background: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      color: $neutrals4;
      transition: all 0.2s;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-bottom: 1px solid $neutrals6;
      width: 50%;

      @include dark {
        border-bottom: 1px solid $neutrals3;
      }

      @include m {
        margin: 0;
      }

      &:hover {
        color: $neutrals3;

        @include dark {
          color: $neutrals6;
        }
      }

      &.active {
        border-bottom: 2px solid $primary3;

        @include dark {
          border-bottom: 2px solid $primary3;
        }
      }

      &:not(:last-child) {
        @include m {
          margin-right: 12px;
        }
      }
    }

    &_time {
      background: $primary3;
      color: $primary2;
      @include caption-bold-1;
      display: inline-flex;
      align-items: center;
      padding: 6px 10px;
      gap: 8px;
      border-radius: 32px;
      align-self: start;

      @include dark {
        background: $primary3;
        color: $primary2;
      }
    }
  }

  &__cards {
    display: list-item;

    @include t {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
    }

    @include m {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-flow: column;
    }

    &_name {
      margin-right: auto;
      padding-top: 1px;
      @include body-bold-2;
    }

    &_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 18px;
      margin-top: 24px;
    }

    &_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
    }

    &_price {
      display: flex;
      justify-content: space-between;
      align-items: normal;
      color: $neutrals3;
      @include hairline-2;

      @include dark {
        color: $primary2;
      }
    }

    &_Target {
      display: flex;
      gap: 4px;
      align-items: center;
    }

    &_bids {
      @include caption-1;
      font-weight: 400;
      color: $neutrals4;
    }

    &_soon {
      background: $primary1;
      color: $neutrals6;
      @include caption-bold-1;
      display: inline-flex;
      align-items: center;
      padding: 2px 10px 6px 10px;
      gap: 8px;
      border-radius: 32px;
      align-self: start;

      @include dark {
        background: $primary2;
        color: $neutrals3;
      }
    }

    &_icon {
      @include dark {
        img {
          @include filter-neutrals1;
        }
      }
    }
  }

  &__index {
    &_Number {
      @include dm-sans;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: -0.02em;
      color: $primary3;
    }

    &_card {
      // padding-top: 23%;
      // padding-bottom: 23%;
      text-align: center;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-left: 315px !important;
      // margin-left: 0 !important;
      margin-right: 13px !important;
      margin-bottom: 20px;
      height: 418px;
      @include t {
        margin-left: 0 !important;
        // margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    &_body {
      padding: 0 87px 40px;
    }

    &_details {
      @include dm-sans;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: -0.02em;
    }

    &_btn {
      border-radius: 40px;
    }

    &_content {
      margin-top: 16px;
      @include main-font;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: $neutrals4;
    }
  }

  .main__box2 {
    color: $primary1;
    @include dark {
      color: $primary2;
    }
  }
}

.museum {
  &.carousel__listings {
    @include m {
      margin: 30px 0;
    }
  }
}

.js-slider-museum {
  .slick-prev {
    top: 643px;
    left: 46.7%;
    font-size: 20px;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: $primary1;
    box-shadow: inset 0 0 0 2px $neutrals6;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      i {
        @include filter-primary3;
      }
    }

    @include t {
      top: 669px;
      left: calc(50% - 56px);
    }

    @include m {
      top: 683px;
      left: calc(50% - 56px);
    }

    @include dark {
      color: $primary2;
      box-shadow: inset 0 0 0 2px $neutrals3;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;

        i {
          @include filter-primary3;
        }
      }
    }
  }

  .slick-next {
    top: 643px;
    right: 46.7%;
    font-size: 20px;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: $primary1;
    box-shadow: inset 0 0 0 2px $neutrals6;

    &:hover {
      box-shadow: inset 0 0 0 2px $primary3;

      i {
        @include filter-primary3;
      }
    }

    @include t {
      top: 669px;
      right: calc(50% - 56px);
    }

    @include m {
      top: 683px;
      right: calc(50% - 56px);
    }

    @include dark {
      color: $primary2;
      box-shadow: inset 0 0 0 2px $neutrals3;

      &:hover {
        box-shadow: inset 0 0 0 2px $primary3;

        i {
          @include filter-primary3;
        }
      }
    }
  }

  .slick-arrow {
    bottom: 0px;
  }
  // work on phone
  .slick-list {
    display: flex;
    height: 490px;
  }

  .slick-track {
    display: flex;
    margin-left: 312px !important;

    @include r(1669) {
      margin-left: 165px !important;
    }

    @include w {
      margin-left: 165px !important;
    }

    @include x {
      margin-left: 90px !important;
    }

    @include t {
      margin-left: 55px !important;
    }
  }

  &__inner {
    margin: 30px -8px;
  }
}

.profileMuseum {
  .profile2__tab {
    flex-direction: column;
  }
}

.circular {
  &__text {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transform: rotate(-50deg);

    &_first {
      .circular__char {
        @for $i from 1 through 100 {
          &.char#{$i} {
            transform: rotate((($i - 3) * 9) + deg);
          }
        }
      }
    }

    &_last {
      .circular__char {
        @for $i from 1 through 100 {
          &.char#{$i} {
            transform: rotate((($i * 9) + 155) + deg);
          }
        }
      }
    }
  }

  &__holder {
    // position: absolute;
    // top: -50px;
    // right: 109px;
    position: absolute;
    top: -39px;
    right: 110px;

    @include m {
      top: 15px;
    }
  }

  &__char {
    height: 140px;
    position: absolute;
    width: 20px;
    left: 0;
    top: 0;
    transform-origin: bottom center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    color: $primary2;
    font-weight: 500;

    @include m {
      height: 85px;
      font-size: 8px;
    }

    @include dark {
      color: $primary1;
    }
  }
}
